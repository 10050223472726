.App {
  text-align: center;
  font-family: "Montserrat";
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2000;
}
